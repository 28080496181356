import React from 'react';
import { ProfileHeadline } from '../../ProfileHeadline/ProfileHeadline';
import { Button } from '../../atoms/Buttons/Button';
import { RichTextDisplay } from '../RichTextDisplay';
import { IPagesElementsInterface } from '../interfaces/IPagesElementsInterface';
import Image from 'next/image';
import { useCMSImage } from '../hooks/useCMSImage';

export interface IPage2col50TextGallery extends IPagesElementsInterface {}

export const Page2col50TextGallery: React.FC<IPage2col50TextGallery> = (props) => {
    const { widgetId, richText1, headline1, images, ctaLink, ctaLabel } = props;
    const { getImageUrl } = useCMSImage();

    return (
        <div className="relative flex w-full flex-col gap-5 sm:gap-10 md:grid md:grid-cols-[6fr,6fr]">
            <div className="relative flex flex-col gap-5 p-4 sm:p-0 md:justify-between">
                <div className="relative flex flex-col justify-start gap-5">
                    {headline1 && <ProfileHeadline type="h2" headline={headline1} />}
                    {richText1 && (
                        <RichTextDisplay text={richText1} additionalCss="text-base sm:text-lg" />
                    )}
                </div>
                {ctaLink && ctaLabel && (
                    <div className="relative flex flex-row justify-end">
                        <div className="relative flex w-max">
                            <Button
                                color="primary"
                                addtionalCss="w-max px-8"
                                href={ctaLink ?? ''}
                                height="h-8"
                            >
                                {ctaLabel ?? 'Button'}
                            </Button>
                        </div>
                    </div>
                )}
            </div>

            <div className="relative flex flex-col gap-4 sm:grid sm:grid-cols-2">
                {images?.data.map((image) => {
                    return (
                        <Image
                            key={`image-${widgetId}-${image.id}`}
                            className="aspect-[1/1] w-full"
                            src={getImageUrl(image.attributes.url) as string}
                            height={image.attributes.height}
                            width={image.attributes.width}
                            loading="lazy"
                            placeholder="blur"
                            // https://png-pixel.com/
                            blurDataURL="/shop/images/blur/1x1-dcdcdc51.png"
                            alt={''}
                        />
                    );
                })}
            </div>
        </div>
    );
};
