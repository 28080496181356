import { gql } from 'graphql-request';
import { useQuery } from '@tanstack/react-query';
import { IGQLRequest, IGQLReturn } from '../../interfaces/gql/request';
import { cmsApiPost } from '../../lib/cmsApi';
import { IContentPages } from '../../interfaces/content/IContentPage';

export const useCMSContent = (slug: string, locale: string) => {
    return useQuery(
        [`get-content-by-slug-${slug}-${locale}`],
        async () => {
            if (!slug) return undefined;

            return cmsApiPost<IGQLReturn<IContentPages>, IGQLRequest>(
                `graphql?c=get-page-${slug}-${locale}`,
                {
                    query: gql`
                        query {
                            pages(filters: {
                                slug: { eq: "${slug}" },
                            },
                                locale: "${locale ?? 'en'}"
                            ) {
                                data {
                                    id
                                    attributes {
                                        title
                                        subTitle
                                        isPublic
                                        slug
                                        widgets {
                                            __typename
                                            ... on ComponentWidgetsPage1ColText {
                                                id
                                                Headline1
                                                RichText1
                                            }
                                            ... on ComponentWidgetsPage2Col8020SlideshowText {
                                                id
                                                RichText1
                                                RichText1
                                                Headline1
                                                Images {
                                                    data {
                                                        id
                                                        attributes {
                                                            name
                                                            alternativeText
                                                            width
                                                            height
                                                            formats
                                                            url
                                                        }
                                                    }
                                                }
                                                ctaLabel
                                                ctaLink
                                            }
                                            ... on ComponentWidgetsPage2Col50SlideshowText {
                                                id
                                                RichText1
                                                Headline1
                                                Images {
                                                    data {
                                                        id
                                                        attributes {
                                                            name
                                                            alternativeText
                                                            width
                                                            height
                                                            formats
                                                            url
                                                        }
                                                    }
                                                }
                                                ctaLabel
                                                ctaLink
                                            }
                                            ... on ComponentWidgetsPage2Col50TextGallery {
                                                id
                                                RichText1
                                                Headline1
                                                Images {
                                                    data {
                                                        id
                                                        attributes {
                                                            name
                                                            alternativeText
                                                            width
                                                            height
                                                            formats
                                                            url
                                                        }
                                                    }
                                                }
                                                ctaLabel
                                                ctaLink
                                            }
                                            ... on ComponentWidgetsPage2Col8020VideoText {
                                                id
                                                RichText1
                                                Headline1
                                                Video1
                                            }
                                            ... on ComponentWidgetsPage2Col8020VideoTextVideoGallery {
                                                id
                                                RichText1
                                                Headline1
                                                Video1
                                                Video2
                                                Video3
                                                Video4
                                            }
                                            ... on ComponentWidgetsPage1ColHero {
                                                id
                                                ctaLabel
                                                ctaLink
                                                Images {
                                                    data {
                                                        id
                                                        attributes {
                                                            name
                                                            alternativeText
                                                            width
                                                            height
                                                            formats
                                                            url
                                                        }
                                                    }
                                                }
                                            }
                                            ... on ComponentWidgetsPage1ColCta {
                                                id
                                                ctaLabel
                                                ctaLink
                                                RichText1
                                                Headline1
                                                Images {
                                                    data {
                                                        id
                                                        attributes {
                                                            name
                                                            alternativeText
                                                            width
                                                            height
                                                            formats
                                                            url
                                                        }
                                                    }
                                                }
                                            }
                                            ... on ComponentWidgetsPage2Col50TwoBannersGap {
                                                id
                                                Images {
                                                    data {
                                                        id
                                                        attributes {
                                                            name
                                                            alternativeText
                                                            width
                                                            height
                                                            formats
                                                            url
                                                        }
                                                    }
                                                }
                                            }
                                            ... on ComponentWidgetsPage2Col50TextText {
                                                id
                                                RichText1
                                                RichText2
                                                Headline1
                                            }
                                            ... on ComponentWidgetsPage2Col8020ImageGallery {
                                                id
                                                Images {
                                                    data {
                                                        id
                                                        attributes {
                                                            name
                                                            alternativeText
                                                            width
                                                            height
                                                            formats
                                                            url
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        createdAt
                                    }
                                }
                            }
                        }
                    `,

                    variables: {},
                }
            );
        },
        { staleTime: 60000 }
    );
};
