import React from 'react';
import { useCMSParser } from './hooks/useCMSParser';

export interface IRichTextDisplay {
    text: string;
    additionalCss?: string;
}

export const RichTextDisplay: React.FC<IRichTextDisplay> = (props) => {
    const { text, additionalCss } = props;
    const { parseContent } = useCMSParser();

    return (
        <>
            {/* Note: See pages.scss for styles */}
            <div
                className={`cms-content pagesRichText relative w-full text-lg text-body ${
                    additionalCss ?? ''
                }`}
                dangerouslySetInnerHTML={{ __html: parseContent(text) }}
            />
        </>
    );
};
