import React from 'react';
import { IContentWidget } from '../../interfaces/content/IContentPage';
import { Page1colText } from '../pages/elements/Page1colText';
import { nanoid } from 'nanoid';
import { Page2col8020SlideshowText } from '../pages/elements/Page2col8020SlideshowText';
import { Page2col50SlideshowText } from '../pages/elements/Page2col50SlideshowText';
import { Page2col50TextGallery } from '../pages/elements/Page2col50TextGallery';
import { Page2col8020VideoText } from '../pages/elements/Page2col8020VideoText';
import { Page2col8020VideoTextVideoGallery } from '../pages/elements/Page2col8020VideoTextVideoGallery';
import { Page1colHero } from '../pages/elements/Page1colHero';
import { Page1colCTA } from '../pages/elements/Page1colCTA';
import { Page2col50TwoBannersGap } from '../pages/elements/Page2col50TwoBannersGap';
import { Page2col50TextText } from '../pages/elements/Page2col50TextText';
import { Page2col8020ImageGallery } from '../pages/elements/Page2col8020ImageGallery';

export interface IWidgetLoader {
    widgets: IContentWidget[];
}

export const WidgetLoader: React.FC<IWidgetLoader> = (props) => {
    const { widgets } = props;

    return (
        <>
            <section className="relative flex flex-col">
                {widgets.map((widget) => {
                    switch (widget.__typename) {
                        case 'ComponentWidgetsPage1ColText':
                            return (
                                <div
                                    className="relative flex w-full flex-col gap-1"
                                    key={`widget${widget.id}-${widget.__typename}`}
                                >
                                    <Page1colText
                                        widgetId={widget.id}
                                        headline1={widget.Headline1}
                                        richText1={widget.RichText1}
                                    />
                                    <hr className="my-12" />
                                </div>
                            );
                        case 'ComponentWidgetsPage2Col8020SlideshowText':
                            return (
                                <div
                                    className="relative flex w-full flex-col gap-1"
                                    key={`widget${widget.id}-${widget.__typename}`}
                                >
                                    <Page2col8020SlideshowText
                                        widgetId={widget.id}
                                        richText1={widget.RichText1}
                                        images={widget.Images}
                                        ctaLabel={widget.ctaLabel}
                                        ctaLink={widget.ctaLink}
                                        headline1={widget.Headline1}
                                    />
                                    <hr className="my-12" />
                                </div>
                            );
                        case 'ComponentWidgetsPage2Col50SlideshowText':
                            return (
                                <div
                                    className="relative flex w-full flex-col gap-1"
                                    key={`widget${widget.id}-${widget.__typename}`}
                                >
                                    <Page2col50SlideshowText
                                        widgetId={widget.id}
                                        richText1={widget.RichText1}
                                        images={widget.Images}
                                        ctaLabel={widget.ctaLabel}
                                        ctaLink={widget.ctaLink}
                                        headline1={widget.Headline1}
                                    />
                                    <hr className="my-12" />
                                </div>
                            );
                        case 'ComponentWidgetsPage2Col50TextGallery':
                            return (
                                <div
                                    className="relative flex w-full flex-col gap-1"
                                    key={`widget${widget.id}-${widget.__typename}`}
                                >
                                    <Page2col50TextGallery
                                        widgetId={widget.id}
                                        richText1={widget.RichText1}
                                        images={widget.Images}
                                        ctaLabel={widget.ctaLabel}
                                        ctaLink={widget.ctaLink}
                                        headline1={widget.Headline1}
                                    />
                                    <hr className="my-12" />
                                </div>
                            );
                        case 'ComponentWidgetsPage2Col8020VideoText':
                            return (
                                <div
                                    className="relative flex w-full flex-col gap-1"
                                    key={`widget${widget.id}-${widget.__typename}`}
                                >
                                    <Page2col8020VideoText
                                        widgetId={widget.id}
                                        headline1={widget.Headline1}
                                        richText1={widget.RichText1}
                                        video1={widget.Video1}
                                    />
                                    <hr className="my-12" />
                                </div>
                            );
                        case 'ComponentWidgetsPage2Col8020VideoTextVideoGallery':
                            return (
                                <div
                                    className="relative flex w-full flex-col gap-1"
                                    key={`widget${widget.id}-${widget.__typename}`}
                                >
                                    <Page2col8020VideoTextVideoGallery
                                        widgetId={widget.id}
                                        headline1={widget.Headline1}
                                        richText1={widget.RichText1}
                                        video1={widget.Video1}
                                        video2={widget.Video2}
                                        video3={widget.Video3}
                                        video4={widget.Video4}
                                    />
                                    <hr className="my-12" />
                                </div>
                            );
                        case 'ComponentWidgetsPage1ColHero':
                            return (
                                <div
                                    className="relative flex w-full flex-col gap-1"
                                    key={`widget${widget.id}-${widget.__typename}`}
                                >
                                    <Page1colHero
                                        widgetId={widget.id}
                                        images={widget.Images}
                                        ctaLabel={widget.ctaLabel}
                                        ctaLink={widget.ctaLink}
                                    />
                                    <hr className="my-12" />
                                </div>
                            );
                        case 'ComponentWidgetsPage1ColCta':
                            return (
                                <div
                                    className="relative flex w-full flex-col gap-1"
                                    key={`widget${widget.id}-${widget.__typename}`}
                                >
                                    <Page1colCTA
                                        widgetId={widget.id}
                                        images={widget.Images}
                                        ctaLabel={widget.ctaLabel}
                                        ctaLink={widget.ctaLink}
                                        headline1={widget.Headline1}
                                        richText1={widget.RichText1}
                                    />
                                    <hr className="my-12" />
                                </div>
                            );
                        case 'ComponentWidgetsPage2Col50TwoBannersGap':
                            return (
                                <div
                                    className="relative flex w-full flex-col gap-1"
                                    key={`widget${widget.id}-${widget.__typename}`}
                                >
                                    <Page2col50TwoBannersGap
                                        widgetId={widget.id}
                                        images={widget.Images}
                                    />
                                    <hr className="my-12" />
                                </div>
                            );
                        case 'ComponentWidgetsPage2Col50TextText':
                            return (
                                <div
                                    className="relative flex w-full flex-col gap-1"
                                    key={`widget${widget.id}-${widget.__typename}`}
                                >
                                    <Page2col50TextText
                                        widgetId={widget.id}
                                        richText1={widget.RichText1}
                                        richText2={widget.RichText2}
                                    />
                                    <hr className="my-12" />
                                </div>
                            );
                        case 'ComponentWidgetsPage2Col8020ImageGallery':
                            return (
                                <div
                                    className="relative flex w-full flex-col gap-1"
                                    key={`widget${widget.id}-${widget.__typename}`}
                                >
                                    <Page2col8020ImageGallery
                                        widgetId={widget.id}
                                        images={widget.Images}
                                    />
                                    <hr className="my-12" />
                                </div>
                            );
                    }

                    return <div key={nanoid()}></div>;
                })}
            </section>
        </>
    );
};
