import { useCallback } from 'react';
import { useEnvironment } from '../../../hooks/useEnvironment';

export const useCMSImage = () => {
    const { isDevelopment } = useEnvironment();

    const getImageUrl = useCallback(
        (url: string) => {
            const endpoint = process.env['NEXT_PUBLIC_CMS_URL'] as string;

            if (isDevelopment && url && endpoint) return endpoint + url;

            if (url) {
                return url;
            }

            return null;
        },
        [isDevelopment]
    );

    return { getImageUrl };
};
