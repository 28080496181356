import React from 'react';
import { IPagesElementsInterface } from '../interfaces/IPagesElementsInterface';
import { ProfileHeadline } from '../../ProfileHeadline/ProfileHeadline';
import { RichTextDisplay } from '../RichTextDisplay';
import { SlideShow } from '../SlideShow';

export interface IPage2col8020SlideshowText extends IPagesElementsInterface {}

export const Page2col8020SlideshowText: React.FC<IPage2col8020SlideshowText> = (props) => {
    const { widgetId, headline1, richText1, ctaLink, ctaLabel, images } = props;

    return (
        <div className="relative flex w-full flex-col gap-5 sm:gap-10 lg:grid lg:grid-cols-[818px,auto]">
            <div className="relative w-full">
                {images && (
                    <SlideShow
                        Images={images}
                        id={widgetId}
                        ctaLink={ctaLink}
                        ctaLabel={ctaLabel}
                    />
                )}
            </div>
            <div className="relative flex flex-col gap-5 p-4 sm:p-0">
                {headline1 && <ProfileHeadline type="h2" headline={headline1} />}
                {richText1 && (
                    <RichTextDisplay text={richText1} additionalCss="text-base sm:text-lg" />
                )}
            </div>
        </div>
    );
};
