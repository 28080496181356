import React from 'react';
import { IPagesElementsInterface } from '../interfaces/IPagesElementsInterface';
import { ProfileHeadline } from '../../ProfileHeadline/ProfileHeadline';
import { RichTextDisplay } from '../RichTextDisplay';
import YouTubePlayer from 'react-player/youtube';

export interface IPage2col8020VideoTextVideoGallery extends IPagesElementsInterface {}

export const Page2col8020VideoTextVideoGallery: React.FC<IPage2col8020VideoTextVideoGallery> = (
    props
) => {
    const { richText1, video1, video2, video3, video4, headline1 } = props;

    return (
        <div className="relative flex w-full flex-col gap-5 sm:gap-10 lg:grid lg:grid-cols-[818px,auto]">
            <div className="relative flex flex-col gap-5">
                {video1 && (
                    <div className="relative aspect-[4/3] w-full">
                        <YouTubePlayer
                            url={video1 ?? ''}
                            width="100%"
                            height="100%"
                            controls={true}
                        />
                    </div>
                )}
                <div className="relative h-max w-full p-4 sm:p-0">
                    {richText1 && (
                        <RichTextDisplay text={richText1} additionalCss="text-base sm:text-lg" />
                    )}
                </div>
            </div>
            <div className="relative flex flex-col justify-start gap-5">
                {headline1 && <ProfileHeadline type="h2" headline={headline1} />}
                <div className="md:max-lg:grid md:max-lg:grid-cols-[6fr,6fr] relative flex flex-col gap-5 ">
                    {video2 && (
                        <YouTubePlayer url={video2} width="100%" height="100%" controls={true} />
                    )}
                    {video3 && (
                        <YouTubePlayer url={video3} width="100%" height="100%" controls={true} />
                    )}
                    {video4 && (
                        <YouTubePlayer url={video4} width="100%" height="100%" controls={true} />
                    )}
                </div>
            </div>
        </div>
    );
};
