import React, { useMemo } from 'react';
import { IPagesElementsInterface } from '../interfaces/IPagesElementsInterface';
import Image from 'next/image';
import { ProfileHeadline } from '../../ProfileHeadline/ProfileHeadline';
import { RichTextDisplay } from '../RichTextDisplay';
import { Button } from '../../atoms/Buttons/Button';
import { useCMSImage } from '../hooks/useCMSImage';

export interface IPage1colCTA extends IPagesElementsInterface {}

export const Page1colCTA: React.FC<IPage1colCTA> = (props) => {
    const { richText1, headline1, ctaLink, ctaLabel, images } = props;

    const { getImageUrl } = useCMSImage();

    const currentImage = useMemo(() => {
        if (images && images.data[0]) return images.data[0];

        return null;
    }, [images]);

    return (
        <div className="relative flex h-max w-full flex-row gap-5 bg-primary-light/10 pb-6 pl-6 pt-4 lg:pr-10">
            <div className="relative hidden w-full max-w-[134px] flex-col justify-center md:flex">
                {currentImage && (
                    <Image
                        className="w-full"
                        src={getImageUrl(currentImage.attributes.url) as string}
                        height={currentImage.attributes.height}
                        width={currentImage.attributes.width}
                        loading="lazy"
                        placeholder="blur"
                        // https://png-pixel.com/
                        blurDataURL="/shop/images/blur/1x1-dcdcdc51.png"
                        alt={''}
                    />
                )}
            </div>

            <div className="relative flex flex-col gap-y-4 lg:pr-24">
                {headline1 && <ProfileHeadline type="h2" headline={headline1} />}
                {richText1 && (
                    <RichTextDisplay text={richText1} additionalCss="text-base sm:text-lg" />
                )}
            </div>
            <div className="relative flex flex-col justify-end">
                {ctaLink && ctaLabel && (
                    <div className="relative flex w-max">
                        <Button
                            color="primary"
                            addtionalCss="w-max px-8"
                            href={ctaLink ?? ''}
                            height="h-8"
                        >
                            {ctaLabel ?? 'Button'}
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
};
