import { useCallback } from 'react';
import { useEnvironment } from '../../../hooks/useEnvironment';

export const useCMSParser = () => {
    const { isDevelopment } = useEnvironment();
    const endpoint = process.env['NEXT_PUBLIC_CMS_URL'] as string;

    const parseContent = useCallback(
        (content: string) => {
            if (!isDevelopment) {
                return content;
            }

            // Parse links for development
            // \/uploads\/[a-zA-Z-0-9_-]+\.jpeg
            return content.replace(/(\/uploads\/[a-zA-Z-0-9_-]+\.(jpeg|jpg|png))/, `${endpoint}$1`);
        },
        [endpoint, isDevelopment]
    );

    return { parseContent };
};
