import React, { useState } from 'react';
import { IMediaSources } from '../../interfaces/content/mediaSource.interface';
import { useCMSImage } from './hooks/useCMSImage';
import Image from 'next/image';
import { Button } from '../atoms/Buttons/Button';
export interface ISlideShow {
    id: string;
    Images?: IMediaSources;
    ctaLink?: string;
    ctaLabel?: string;
}

export const SlideShow: React.FC<ISlideShow> = (props) => {
    const { Images, id, ctaLink, ctaLabel } = props;

    const { getImageUrl } = useCMSImage();

    const [currentImage, setCurrentImage] = useState(Images?.data[0] ?? null);

    return (
        <>
            <div className="relative flex h-full w-full flex-col gap-4">
                <div className="relative flex aspect-[4/3] w-full flex-row gap-1">
                    {currentImage && getImageUrl(currentImage.attributes.url) && (
                        <Image
                            className="w-full"
                            src={getImageUrl(currentImage.attributes.url) as string}
                            height={currentImage.attributes.height}
                            width={currentImage.attributes.width}
                            loading="lazy"
                            placeholder="blur"
                            // https://png-pixel.com/
                            blurDataURL="/shop/images/blur/1x1-dcdcdc51.png"
                            alt={''}
                        />
                    )}
                    {ctaLink && ctaLabel && (
                        <div className="absolute bottom-0 right-0 flex h-max w-full flex-row items-center justify-end p-4">
                            <div className="relative flex w-max">
                                <Button
                                    color="primary"
                                    addtionalCss="w-max px-8"
                                    href={ctaLink ?? ''}
                                    height="h-8"
                                >
                                    {ctaLabel ?? 'Button'}
                                </Button>
                            </div>
                        </div>
                    )}
                </div>

                <div className="relative flex w-full flex-row gap-4">
                    {Images?.data.map((image) => {
                        if (currentImage && image.id === currentImage.id) {
                            return undefined;
                        }

                        return (
                            <Image
                                className="relative aspect-[4/3] cursor-pointer ring-offset-2 transition-all hover:ring-2 hover:ring-primary"
                                key={`image-${id}-${image.id}`}
                                src={getImageUrl(image.attributes.url) as string}
                                height={image.attributes.height / 2}
                                width={image.attributes.width / 2}
                                loading="lazy"
                                placeholder="blur"
                                // https://png-pixel.com/
                                blurDataURL="/shop/images/blur/1x1-dcdcdc51.png"
                                alt={''}
                                onClick={() => {
                                    setCurrentImage(image);
                                }}
                            />
                        );
                    })}
                </div>
            </div>
        </>
    );
};
