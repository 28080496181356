import React, { useMemo } from 'react';
import { IPagesElementsInterface } from '../interfaces/IPagesElementsInterface';
import Image from 'next/image';
import { useCMSImage } from '../hooks/useCMSImage';
import { Button } from '../../atoms/Buttons/Button';

export interface IPage1colHero extends IPagesElementsInterface {}

export const Page1colHero: React.FC<IPage1colHero> = (props) => {
    const { images, ctaLabel, ctaLink } = props;

    const { getImageUrl } = useCMSImage();

    const currentImage = useMemo(() => {
        if (images && images.data[0]) return images.data[0];

        return null;
    }, [images]);

    return (
        <div className="relative aspect-[16/9] w-full">
            {currentImage && (
                <Image
                    className="w-full"
                    src={getImageUrl(currentImage.attributes.url) as string}
                    height={currentImage.attributes.height}
                    width={currentImage.attributes.width}
                    loading="lazy"
                    placeholder="blur"
                    // https://png-pixel.com/
                    blurDataURL="/shop/images/blur/1x1-dcdcdc51.png"
                    alt={''}
                />
            )}
            {ctaLink && ctaLabel && (
                <div className="absolute bottom-0 right-0 flex h-max w-full flex-row items-center justify-end p-4">
                    <div className="relative flex w-max">
                        <Button
                            color="primary"
                            addtionalCss="w-max px-8"
                            href={ctaLink ?? ''}
                            height="h-8"
                        >
                            {ctaLabel ?? 'Button'}
                        </Button>
                    </div>
                </div>
            )}
        </div>
    );
};
