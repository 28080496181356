import React from 'react';
import { IPagesElementsInterface } from '../interfaces/IPagesElementsInterface';
import { ProfileHeadline } from '../../ProfileHeadline/ProfileHeadline';
import { RichTextDisplay } from '../RichTextDisplay';
import YouTubePlayer from 'react-player/youtube';

export interface IPage2col8020VideoText extends IPagesElementsInterface {}

export const Page2col8020VideoText: React.FC<IPage2col8020VideoText> = (props) => {
    const { richText1, video1, headline1 } = props;

    return (
        <div className="relative flex w-full flex-col gap-5 sm:gap-10 lg:grid lg:grid-cols-[818px,auto]">
            <div className="relative aspect-[4/3] w-full">
                {video1 && (
                    <YouTubePlayer url={video1} width="100%" height="100%" controls={true} />
                )}
            </div>
            <div className="relative flex flex-col justify-end gap-5 p-4 sm:p-0">
                {headline1 && <ProfileHeadline type="h2" headline={headline1} />}
                {richText1 && (
                    <RichTextDisplay text={richText1} additionalCss="text-base sm:text-lg" />
                )}
            </div>
        </div>
    );
};
