import React from 'react';
import { WidgetLoader } from './WidgetLoader';
import { SkeletonLine } from '../Skeletons/SkeletonLine';
import { useGetCMSContent } from '../../hooks/useGetCMSContent';

export interface IContentPageProps {
    slug?: string;
    locale?: string;
}

export const ContentPage: React.FC<IContentPageProps> = (props) => {
    const { slug, locale } = props;

    const { isLoading, dataFinal, widgets } = useGetCMSContent({
        slug,
        locale,
    });

    return (
        <>
            <div className="relative mx-auto flex w-full max-w-6xl flex-col gap-10 py-10 px-4 lg:px-16">
                {!isLoading && dataFinal && (
                    <>
                        {dataFinal && (
                            <>
                                <div className="relative flex flex-col gap-0 lg:gap-2">
                                    <h1 className="break-words text-2xl font-bold leading-snug text-primary  md:text-4xl md:leading-normal">
                                        {dataFinal.title ?? ''}
                                    </h1>
                                    <h1 className="break-words text-2xl font-bold leading-snug text-secondary md:text-4xl md:leading-normal">
                                        {dataFinal.subTitle ?? ''}
                                    </h1>
                                </div>
                                <WidgetLoader widgets={widgets ?? []} />
                            </>
                        )}
                    </>
                )}

                {isLoading === null && (
                    <>
                        <div className="relative flex w-full flex-col gap-1">
                            <SkeletonLine height={'small'} />
                            <SkeletonLine height={'large'} />
                            <SkeletonLine height={'large'} />
                            <SkeletonLine height={'large'} />
                            <SkeletonLine height={'large'} />
                            <SkeletonLine height={'large'} />
                        </div>
                    </>
                )}
            </div>
        </>
    );
};
