import { useMemo } from 'react';

export const useEnvironment = () => {
    const isDevelopment = useMemo(() => {
        const env = process.env.NODE_ENV ?? 'development';

        return env === 'development';
    }, []);

    return { isDevelopment };
};
