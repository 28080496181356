import React from 'react';
import { IPagesElementsInterface } from '../interfaces/IPagesElementsInterface';
import Image from 'next/image';
import { useCMSImage } from '../hooks/useCMSImage';

export interface IPage2col50TwoBannersGap extends IPagesElementsInterface {}

export const Page2col50TwoBannersGap: React.FC<IPage2col50TwoBannersGap> = (props) => {
    const { images, widgetId } = props;

    const { getImageUrl } = useCMSImage();

    return (
        <div className="relative flex w-full flex-col gap-10 md:grid md:grid-cols-[6fr,6fr]">
            {images?.data.map((image) => {
                return (
                    <Image
                        className="relative w-full"
                        key={`image-${widgetId}-${image.id}`}
                        src={getImageUrl(image.attributes.url) as string}
                        height={image.attributes.height / 2}
                        width={image.attributes.width / 2}
                        loading="lazy"
                        placeholder="blur"
                        // https://png-pixel.com/
                        blurDataURL="/shop/images/blur/1x1-dcdcdc51.png"
                        alt={''}
                    />
                );
            })}
        </div>
    );
};
