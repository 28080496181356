import axios from 'axios';

export const cmsApiPost = async <T, G>(path: string, data?: G): Promise<T> => {
    return axios
        .post<T>(`${String(process.env['NEXT_PUBLIC_CMS_URL'])}/${path}`, data, {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${process.env['NEXT_PUBLIC_CMS_TOKEN'] ?? ''}`,
            },
        })
        .then((res) => res.data);
};
