import React, { useMemo } from 'react';
import { IPagesElementsInterface } from '../interfaces/IPagesElementsInterface';
import { useCMSImage } from '../hooks/useCMSImage';
import Image from 'next/image';

export interface IPage2col8020ImageGallery extends IPagesElementsInterface {}

export const Page2col8020ImageGallery: React.FC<IPage2col8020ImageGallery> = (props) => {
    const { widgetId, images } = props;

    const { getImageUrl } = useCMSImage();

    const currentImage = useMemo(() => {
        if (images && images.data[0]) return images.data[0];

        return null;
    }, [images]);

    return (
        <div className="relative flex w-full flex-col gap-5 sm:gap-10 lg:grid lg:grid-cols-[819px,auto]">
            <div className="relative">
                {currentImage && (
                    <Image
                        className="aspect-[4/3] w-full"
                        src={getImageUrl(currentImage.attributes.url) as string}
                        height={currentImage.attributes.height}
                        width={currentImage.attributes.width}
                        loading="lazy"
                        placeholder="blur"
                        // https://png-pixel.com/
                        blurDataURL="/shop/images/blur/1x1-dcdcdc51.png"
                        alt={''}
                    />
                )}
            </div>

            <div className="relative flex flex-col gap-5 sm:flex-row sm:gap-7 lg:flex-col">
                {images?.data.map((image) => {
                    if (currentImage && image.id === currentImage.id) {
                        return undefined;
                    }

                    return (
                        <Image
                            className="relative w-full"
                            key={`image-${widgetId}-${image.id}`}
                            src={getImageUrl(image.attributes.url) as string}
                            height={image.attributes.height / 2}
                            width={image.attributes.width / 2}
                            loading="lazy"
                            placeholder="blur"
                            // https://png-pixel.com/
                            blurDataURL="/shop/images/blur/1x1-dcdcdc51.png"
                            alt={''}
                        />
                    );
                })}
            </div>
        </div>
    );
};
