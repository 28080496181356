import React from 'react';
import { IPagesElementsInterface } from '../interfaces/IPagesElementsInterface';
import { RichTextDisplay } from '../RichTextDisplay';
import { ProfileHeadline } from '../../ProfileHeadline/ProfileHeadline';

export interface IPage1colText extends IPagesElementsInterface {}

export const Page1colText: React.FC<IPage1colText> = (props) => {
    const { richText1, headline1 } = props;

    return (
        <div className="relative flex h-max w-full flex-col gap-4 lg:grid lg:grid-cols-[816px,auto] lg:gap-10">
            <div className="relative flex flex-col gap-5">
                {headline1 && <ProfileHeadline type="h2" headline={headline1} />}
                {richText1 && (
                    <RichTextDisplay
                        text={richText1}
                        additionalCss="text-base p-0 md:p-4 sm:p-0 sm:text-lg"
                    />
                )}
            </div>
            <div></div>
        </div>
    );
};
