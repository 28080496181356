import React from 'react';
import { ProfileHeadline } from '../../ProfileHeadline/ProfileHeadline';
import { RichTextDisplay } from '../RichTextDisplay';
import { IPagesElementsInterface } from '../interfaces/IPagesElementsInterface';

export interface IPage2col50TextText extends IPagesElementsInterface {}

export const Page2col50TextText: React.FC<IPage2col50TextText> = (props) => {
    const { richText1, richText2, headline1 } = props;

    return (
        <div className="relative flex w-full flex-col gap-5 p-4 sm:gap-10 sm:p-0 md:grid md:grid-cols-[6fr,6fr]">
            {headline1 && <ProfileHeadline type="h2" headline={headline1} />}

            <div className="relative flex flex-col justify-end gap-5">
                {richText1 && (
                    <RichTextDisplay text={richText1 ?? ''} additionalCss="text-base sm:text-lg" />
                )}
            </div>
            <div className="relative flex flex-col justify-start md:pt-11">
                {richText1 && (
                    <RichTextDisplay text={richText2 ?? ''} additionalCss="text-base sm:text-lg" />
                )}
            </div>
        </div>
    );
};
